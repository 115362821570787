<template>
  <div>
    <page-header
      :title="'Coupons'"
      :sub-heading="true"
      :sub-header="'Dashboard'"
      :link-name="'Coupons'"
      :link-url="'/coupons'"
    >
    </page-header>

    <div class="csp sub-page-container row">
      <div class="col-xs-12">
        <div class="csp__nav tab-nav">
          <router-link :to="{ name: 'create-coupon' }" class="tab-nav__el" exact
            >Create Coupon
          </router-link>
          <router-link
            :to="{ name: 'active-coupons' }"
            class="tab-nav__el"
            exact
            >Active Coupon
          </router-link>
        </div>

        <div class="csp__content tab-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../layouts/components/fullPage/PageHeader";
export default {
  name: "ManageCoupons",
  components: { PageHeader },
};
</script>

<style scoped></style>
